/* Collage.css */

/* Стили для модального окна */
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7); /* Прозрачный цвет для затемнения */
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999; /* Поверх всех элементов */
}

.modal {
	display: flex;
	justify-content: center;
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
	max-width: 700px;
	height: 700px;
	overflow: auto;
	position: absolute;
}
.modal img {
	position: relative;
	max-width: 100%;
}
.close {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	font-size: 24px;
}

@media (max-width: 700px) {
	.modal {
		display: flex;
		justify-content: center;
		max-width: 300px;
		max-height: 300px;
	}
}
