@font-face {
	font-family: "Igra Sans";
	src: local("Igra Sans"), local("IgraSans"),
		url("./Igrasans.woff2") format("woff2"),
		url("./Igrasans.woff") format("woff"),
		url("./Igrasans.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

.product-config {
	display: flex;
	flex-wrap: wrap;
	width: 500px;
	justify-content: center;

	.config-section {
		display: inline-block;
		margin: 0;

		border: 0px solid #ccc;
		padding: 10px;
		flex-direction: column;

		h3 {
			font-family: "Igra Sans";
			margin-bottom: 10px;
		}
		li {
			font-size: 13px;
			margin-left: 20px;
			list-style-type: none;
		}

		label.chekid-19 {
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			-webkit-text-size-adjust: none;
			font-family: "Avenir";
			box-sizing: border-box;
			outline: 0;
			max-width: 100%;
			font-size: 12px;
			min-height: 20px;
			transition: all 0.6s ease-in-out;
			margin: 0 5px 0 0;
			padding: 0 10px;
			border: none;
			transition-duration: 0.2s;
			transition-property: all;
			transition-timing-function: cubic-bezier(0, 0, 1, 1);
			cursor: pointer;
			display: inline-block;
			background: #ada479;
			position: relative;
			background-color: transparent !important;
			color: #272c32 !important;
			width: 100%;
			line-height: 18px !important;
			height: auto;
			padding-top: 2.5px;
			margin-bottom: 5px;
			font-weight: 400;
		}

		label.chekid-19.selected {
			background: #ffffff; /* Замените на ваш цвет фона для выбранного элемента */
		}

		label.chekid-19 input[type="checkbox"] {
			display: none; /* Скрываем оригинальный чекбокс */
		}

		label.chekid-19 .checkmark {
			margin-right: 20px;
			position: absolute;
			max-width: initial !important;
			background-color: #eee;
			border-radius: 50%;
			border: 1px solid rgba(130, 130, 130, 0.2);
			cursor: pointer;
			top: 2px;
			left: 0;
			height: 21px;
			width: 21px;
			box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.1);
			transition: all 0.6s ease-in-out;
		}

		label.chekid-19 .checkmark::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 14px;
			height: 14px;
			background-color: rgb(179, 111, 111);
			border-radius: 50%;
			transition: opacity 0.5s ease-in-out; /* Добавляем анимацию */
			opacity: 0; /* Начальное значение нулевой прозрачности */
		}

		label.chekid-19 input[type="checkbox"]:checked + .checkmark::after {
			opacity: 1; /* Конечное значение прозрачности */
		}
	}
	input[type="text"] {
		margin-right: 5px;
		margin-top: 10px;
		padding: 8px;
		font-size: 16px;
		width: 120px; /* Ширина инпутов */
		border: 1px solid #ccc;
		border-radius: 16px;
		box-sizing: border-box;
	}
}

@media (max-width: 1550px) {
	.product-config {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		justify-content: center;
		overflow: auto;

		.config-section {
			display: inline-block;
			border: 0px solid #ccc;
			padding: 10px;
			flex-direction: column;

			h3 {
				font-family: "Igra Sans";
				margin-bottom: 10px;
			}
			li {
				font-size: 13px;
				white-space: normal;
			}

			label.chekid-19 {
				-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
				-webkit-text-size-adjust: none;
				font-family: "Avenir";
				box-sizing: border-box;
				outline: 0;
				max-width: 100%;
				font-size: 12px;
				min-height: 20px;
				transition: all 0.6s ease-in-out;
				margin: 0 5px 0 0;
				padding: 0 10px;
				border: none;
				transition-duration: 0.2s;
				transition-property: all;
				transition-timing-function: cubic-bezier(0, 0, 1, 1);
				cursor: pointer;
				display: inline-block;
				background: #ada479;
				position: relative;
				background-color: transparent !important;
				color: #272c32 !important;
				width: 100%;
				line-height: 18px !important;
				height: auto;
				padding-top: 2.5px;
				margin-bottom: 5px;
				font-weight: 400;
			}

			label.chekid-19.selected {
				background: #ffffff; /* Замените на ваш цвет фона для выбранного элемента */
			}

			label.chekid-19 input[type="checkbox"] {
				display: none; /* Скрываем оригинальный чекбокс */
			}

			label.chekid-19 .checkmark {
				margin-right: 20px;
				position: absolute;
				max-width: initial !important;
				background-color: #eee;
				border-radius: 50%;
				border: 1px solid rgba(130, 130, 130, 0.2);
				cursor: pointer;
				top: 2px;
				left: 0;
				height: 21px;
				width: 21px;
				box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.1);
				transition: all 0.6s ease-in-out;
			}

			label.chekid-19 .checkmark::after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 14px;
				height: 14px;
				background-color: rgb(179, 111, 111);
				border-radius: 50%;
				transition: opacity 0.5s ease-in-out; /* Добавляем анимацию */
				opacity: 0; /* Начальное значение нулевой прозрачности */
			}

			label.chekid-19 input[type="checkbox"]:checked + .checkmark::after {
				opacity: 1; /* Конечное значение прозрачности */
			}
		}
	}
}
