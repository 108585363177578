.card {
	display: flex;
	font-family: "Inter";
	border: 1px solid rgba(255, 255, 255, 0.192);
	padding: 30px;
	width: 200px;
	border-radius: 40px;
	background: rgb(255, 255, 255);
	border-radius: 40px;
	transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
	margin-bottom: 35px;
	margin: 31.5px 31.5px;
	flex-direction: column;

	&:hover {
		box-shadow: 0px 20px 35px rgba(0, 0, 0, 0.07);
		transform: translateY(-5px);
	}

	a {
		display: flex;
		justify-content: space-around;
		flex-direction: column;
		color: black;
		text-decoration: none;
		border: none !important;
	}

	.favorite {
		position: absolute;
		cursor: pointer;
		transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;

		&:hover {
			box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.07);
			transform: translateY(-5px);
		}
	}

	span {
		font-size: 14px;
		opacity: 0.5;
		text-transform: uppercase;
	}

	b {
		font-size: 19px;
	}

	h5 {
		margin: 15px;
		font-size: 14px;
		margin-left: 0;
		white-space: wrap;
		overflow: hidden;
	}

	.plus {
		margin-left: 15px;
		margin-top: 30px;
		transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;

		&:hover {
			box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.07);
			transform: translateY(2px);
		}
	}
}
