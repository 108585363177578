.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1001;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
	overflow: hidden;
}

.overlayVisible {
	visibility: visible;
	opacity: 1;
	.drawer {
		transform: translateX(0);
	}
}

.drawer {
	justify-content: center;
	display: flex;
	flex: 1;
	position: absolute;
	width: 450px;
	height: 100%;
	right: 0;
	background: #ffffff;
	box-shadow: -10px 4px 24px rgba(0, 0, 0, 0.01);
	transform: translate(100%);
	transition: transform 0.3s ease-in-out;

	.items {
		width: 390px;
		overflow: auto;
	}
	h2 {
		margin: 0;
	}
}

@media (max-width: 450px) {
	.drawer {
		width: 100%;
	}
}

.fuckingDrawer {
	margin-right: 20px;
	flex: 1;
}

.drawerINputs{
	text-align: center;
	padding: 8px;
	font-size: 16px;
	width: 60px;
	border: 0px solid #ccc;
	outline: none;
	box-sizing: border-box;
	position: relative;
	z-index: 0;

	&::before,
	&::after {
		content: "";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px;
		position: absolute;
		z-index: -1;
	}

	&::before {
		top: -11px;
		left: 50%;
		border-color: transparent transparent #ccc transparent;
		transform: translateX(-50%);
	}

	&::after {
		bottom: -11px;
		left: 50%;
		border-color: #ccc transparent transparent transparent;
		transform: translateX(-50%);
	}
}
