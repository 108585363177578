@font-face {
	font-family: "Igra Sans";
	src: local("Igra Sans"), local("IgraSans"),
		url("./fonts/Igrasans.ttf") format("woff2"),
		url("./fonts/Igrasans.woff") format("woff"),
		url("./fonts/Igrasans.woff2") format("truetype");
	font-weight: 400;
	font-style: normal;
}

body {
	user-select: none;
	margin: 0;
	font-family: "Inter", system-ui;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: rgba(188, 155, 155, 0.537);
	overflow-y: scroll;
}

@media (max-width: 500px) {
	body {
		overflow-x: hidden;
	}
}

* {
	font-family: "Inter", system-ui;
}

.header {
	display: flex;
	position: fixed;
	width: 100%;
	flex-direction: column;
	font-family: "Inter";
	z-index: 1000;
	top: 0;
	left: 0;
}

.PHONE {
	display: flex;
	margin-bottom: 0;
	justify-content: flex-start;
	width: 100%;
	font-size: 50%;
	white-space: nowrap;
	text-decoration: none;
	font-size: 100%;
	font-family: "Inter";
	color: #f9f9f9;
}

.PHONE2 {
	margin-top: 5px;
}

.upheader {
	position: static;
	background: #2c0101;
	height: 16px;

	a {
		font-family: "Inter";
		font-size: 100%;
		text-decoration: none;
		color: #f9f9f9;
		margin-inline: 10px;
		white-space: nowrap;
	}
}

@media (max-width: 660px) {
	.topheader {
		//	margin: auto;
		.container {
			justify-content: space-around;
		}
	}
}

@media (min-width: 660px) {
	.container {
		justify-content: center;
	}
}

.topheader {
	max-height: 80px;
	display: flex;
	position: relative;
	height: 10%;
	background: #7a0000;
	align-items: center;
	justify-content: space-around;
	color: #f9f9f9;
	background: rgb(113, 9, 9);
	background: linear-gradient(
		17deg,
		rgba(113, 9, 9, 1) 0%,
		rgba(136, 56, 22, 1) 81%,
		rgba(113, 9, 9, 1) 100%
	);

	.container {
		max-height: 80px;
		margin: auto;
		min-width: 100%;
		display: flex;
		width: 100%;
	}

	@media (min-width: 950px) {
		.headerMiddle {
			margin-left: 20%;
			justify-content: right;
		}
	}
	@media (max-width: 950px) {
		.headerMiddle {
			width: 0;
		}

		.phone {
			display: none;
		}
	}
	.phone {
		font-family: "Inter";
		font-size: 85%;
		margin: auto;
	}

	.headerRight {
		font-family: "Inter";
		list-style: none;
		display: flex;
		align-items: center;
		width: auto;
		.user {
			justify-content: center;
			height: 25px;
			width: 25px;
			user-select: none;
			margin: 10px;
		}
		.heart {
			height: 25px;
			width: 25px;
			user-select: none;
			margin-right: 0;
			color: #ffffff;
			margin: 10px;
		}
		.backBasket {
			width: 45px;
			height: 45px;
		}
		.basketPrice {
			display: flex;
			white-space: nowrap;
		}

		@media (max-width: 440px) {
		
			.basketPrice {
				user-select: none;
				margin-left: 10px;
				display: none;
			}

			.user {
				margin: 0;
			}
			.heart {
				margin: 0;
			}
			.backBasket {
				margin: 0px;
				width: 25px;
			}
		}
		li {
			margin: 10px;
			display: flex;
			align-items: center;
			span {
				user-select: none;
				margin-left: 10px;
			}
		}
	}
}

@media (max-width: 800px) {
	.uperheader,
	.uperheader * {
		display: none;
	}
}

@media (min-width: 800px) {
	.uperheader {
		display: flex;
		position: relative;
		height: 30px;
		justify-content: center;
		align-items: center;
		z-index: 1;
		background: rgb(189, 89, 89);
		background: linear-gradient(
			17deg,
			rgba(189, 89, 89, 1) 0%,
			rgba(136, 56, 22, 1) 81%,
			rgba(113, 9, 9, 1) 100%
		);

		.container {
			width: 80%;
			justify-content: center;
			align-content: center;
		}

		a {
			display: flex;
			justify-content: center;
			width: 100%;
			font-size: 50%;
			white-space: nowrap;
			text-decoration: none;
			font-size: 100%;
			font-family: "Inter";
			color: #f9f9f9;
		}

		.dropdownUperHeader {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			white-space: nowrap;
			text-decoration: none;
			font-size: 16px;
			font-family: "Inter";
			color: #f9f9f9;
		}
		.dropdown {
			position: fixed;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			white-space: nowrap;
			text-decoration: none;
			font-size: 16px;
			font-family: "Inter";
			color: #f9f9f9;
		}

		@keyframes slideIn {
			from {
				transform: translateX(-1000%);
			}
			to {
				transform: translateX(0);
			}
		}

		.dropdown-content {
			top: 101%;
			display: block;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: absolute;
			background-color: #8a3737;
			min-width: 220px;
			box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
			z-index: 1;
			animation: slideIn 0.4s ease-in-out;
		}

		.dropdown:hover .dropdown-content {
			display: block;
		}

		/* Стили для пунктов меню */
		.dropdown-content a {
			color: #f9f9f9;
			padding: 10px 16px;
			text-decoration: none;
			display: block;
		}

		.dropdown-content a:hover {
			background-color: #6b1818;
		}
	}
}

@media (min-width: 450px) {
	.wrapper {
		width: 70%;
		height: 100%;
	}
}

@media (min-width: 790px) {
	.wrapper {
		padding: 40px;
	}
	.textwrap {
		margin-left: -40px;
		margin-right: -40px;
	}
	.zamerWidth {
		margin-left: -40px;
		margin-right: -40px;
	}
}

@media (max-width: 790px) {
	.wrapper {
		padding: 20px;
	}
	.textwrap {
		margin-left: -20px;
		margin-right: -20px;
	}
	.zamerWidth {
		margin-left: -20px;
		margin-right: -20px;
	}
}

.wrapper {
	height: 100%;
	margin: auto;
	background: #f9f9f9;
	border-radius: 0px 0px 30px 30px;
	position: relative;
	@media (min-width: 1200px) {
		h2 {
			border-bottom: 1px solid #c9b6b6;
			margin-left: auto;
			font-size: 40px;
			font-family: "Russo One";
		}
	}
	@media (max-width: 1200px) {
		h2 {
			border-bottom: 1px solid #c9b6b6;
			margin-left: auto;
			margin-top: 10px;
			font-size: 30px;
			font-family: "Russo One";
		}
	}
}

.containerItem {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.button {
	background: rgba(241, 234, 229, 0.58);
	border: 0 solid #eb5a1e;
	border-radius: 20px;
	box-sizing: border-box;
	height: auto;
	width: auto;
	cursor: pointer;
}

.search-block {
	display: flex; /* Показать блок при достижении нужной ширины экрана */
	border: 1px solid #ffffff;
	position: relative;
	border-radius: 20px;
	padding: 1% 5%;
	background-color: #ffffff;
	align-items: center;
	height: 20px;
	width: 200px;
}

.search-block .clear {
	display: block; /* Показать .clear */
	position: absolute;
	right: 1%;
	width: 11%;
}

.search-block input {
	display: block; /* Показать input */
	height: 20px;
	width: 200px;
	display: flex;
	outline: none;
	border: 0;
	font-size: 14px;
	padding: 3px;
}

.cartEmpty {
	text-align: center;
	width: 100%;

	p {
		width: 100%;
		line-height: 24px;
	}

	.redButton {
		width: 245px;
		margin-top: 20px;

		&:hover {
			img {
				transform: rotate(180deg) translateX(10px);
			}
		}

		img {
			position: relative;
			top: 1px;
			right: 0%;
			transform: rotate(180deg);
			margin-right: 15px;
			transition: transform 0.3s ease-in-out;
		}
	}
}

@media (max-width: 800px) {
	.search-block {
		margin-left: 20px;
		width: 100px;
		height: 20px;
	}
	.search-block input {
		width: 70px;
		height: 15px;
	}
}

.items {
	flex: 1;
	flex-wrap: nowrap;
	position: relative;
	.cartItem {
		border: 1px solid #f3f3f3;
		border-radius: 20px;
		overflow: auto;
		padding: 20px;

		.cartItemImg {
			height: 70px;
			width: 60px;
			background-size: contain;
			background-position: 0 0px;
			background-repeat: no-repeat;
			margin-right: 10px;
		}

		p {
			font-size: 15px;
			margin: 0;
		}
		b {
			font-size: 17px;
		}

		.removebtn {
			opacity: 0.5;
			cursor: pointer;
			transition: opacity 0.15s ease-in-out;

			&:hover {
				opacity: 1;
			}
		}
	}
}

.cartTotalBlock {
	display: flex;
	flex: 1;
	justify-content: flex-end;
	flex-direction: column;
	ul {
		justify-content: flex-end;
		padding: 0;
		display: block;
		margin-bottom: 30px;
		li {
			display: flex;
			align-items: flex-end;
			margin-bottom: 20px;

			div {
				flex: 1;
				height: 1px;
				border-bottom: 1px dashed #dfdfdf;
				position: relative;
				top: -4px;
				margin: 0 7px;
			}
		}
	}
	.redButton {
		position: relative;

		&:disabled {
			animation: button-loading 0.6 ease-in-out infinite;
		}

		&:hover {
			img {
				transform: translateX(5px);
			}
		}
		img {
			position: absolute;
			right: 30px;

			transition: transform 0.2s ease-in-out;
		}
	}
}

.redButton {
	width: 100%;
	height: 55px;
	background: #a8342d;
	border-radius: 18px;
	border: 0;
	color: #ffffff;
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
	transition: background 0.1s ease-in-out;

	&:disabled {
		background-color: #bebebe !important;
		animation: button-loading 0.6 ease-in-out infinite;
		cursor: default;
	}

	&:hover {
		background: lighten(#a8342d, 4%);
		img {
			transform: translateX(5px);
		}
	}
	&:active {
		background: darken(#a8342d, 4%);
	}
	img {
		margin-top: 3px;
		position: absolute;
		right: 13%;
		transition: transform 0.2s ease-in-out;
	}
}

@keyframes button-loading {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.7;
	}
	100% {
		opacity: 1;
	}
}

.quantity-control {
	width: auto;
	display: flex;
	align-items: center;
	margin-top: 10px;
	justify-content: center;
	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	.border-quantity-control {
		border-bottom: 1px solid #c28888;
		border-top: 1px solid #c28888;
	}

	input[type="number"] {
		appearance: textfield;
		background-color: #f9f9f9;
	}

	button {
		background-color: #c28888;
		border: none;
		font-size: 1.2em;
		cursor: pointer;
		padding: 0.2em 0.5em;
		transition: background-color 0.3s;

		&:hover {
			background-color: #772c2c;
		}

		&:disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}

	input {
		width: 136px;
		text-align: center;
		font-size: 1em;
		border: 0 solid #ff00b3;

		padding: 0.3em;
	}
}

.fullpagetitle {
	margin-bottom: 0;
}

@media (min-width: 1500px) {
	.borderFullPage {
		border-left: 2px solid #d41616;
		border-right: 2px solid #d41616; /* Замените #000 на цвет, который вы хотите использовать */
	}
}

.model {
	font-size: 14px;
	color: rgb(97, 20, 87);
}

.orderPage {
	display: flex;
}

.quantity-button {
	justify-content: center;
	background-color: #c28888;
	border: none;
	font-size: 16px; /* Размер шрифта в пикселях */
	cursor: pointer;
	padding: 5px 10px; /* Внутренний отступ в пикселях */
	transition: background-color 0.3s;
	width: 250px;
	height: 50px;
	margin-top: 30px;
	border-radius: 15px;

	&:hover {
		background-color: #772c2c;
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
}

.quantity-input {
	width: 115px; /* Ширина в пикселях */
	text-align: center;
	font-size: 16px; /* Размер шрифта в пикселях */
	border: 1px solid #ccc;
	margin: 0 5px; /* Отступы в пикселях */
	padding: 5px; /* Внутренний отступ в пикселях */
}

.addtoCart {
	color: #ffffff;
}

.buttonQuantity {
	color: #ffffff;
}

.addH2 {
	h1 {
		margin-top: 40px;
		font-size: 26px;
		font-family: "Igra Sans";
		border-bottom: 1px solid #9e3838;
	}
}
@media (max-width: 1000px) {
	.addH2 {
		h1 {
			margin-top: 40px;
			font-size: 10px;
			font-family: "Igra Sans";
			border-bottom: 1px solid #9e3838;
		}
	}
}

.FullOrderPages {
	font-family: "Igra Sans";
	h1 {
		font-family: "Igra Sans";
		font-size: 36px;
	}
	@media (max-width: 1000px) {
		h1 {
			font-size: 20px;
		}
		.aboutTEXT {
			font-size: small;
		}
	}

	.conttainer {
		margin-top: 40px;
	}

	.ABOUTPRODUCT {
		border-bottom: 1px solid #9e3838;
		margin-top: 40px;
	}

	.conttainerdoors {
		margin-top: 40px;
	}
	.totalPRICE {
		display: flex;
		justify-content: center;
		width: 290px;
		margin-top: 40px;
		color: red;
		font-family: "Igra Sans";
		margin-bottom: 40px;
	}
	p {
		font-size: 20px;
		justify-content: center;
	}

	@media (max-width: 1000px) {
		.conttainerdoors {
			margin-top: 0px;
			img {
				width: 200px;
				height: 420px;
			}
		}

		@media (max-width: 1000px) {
			.conttainer {
				margin-top: 0px;
				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.totalPRICE {
			display: flex;
			justify-content: center;
			width: auto;
			margin: auto;
			color: red;
			font-family: "Igra Sans";
			margin-top: 20px;
			margin-bottom: 20px;
		}
		p {
			font-size: x-large;
		}
	}
}

@media (max-width: 1300px) {
	.orderPage {
		flex-direction: column;
		img {
			width: 100%;
			height: 100%;
			margin: auto;
			display: flex;
		}
	}
}

.collage {
	display: flex;
	flex-direction: row; /* Фотографии идут снизу вверх */
	flex-wrap: wrap;
	align-items: center; /* Выравниваем по центру */
	justify-content: space-between;
}

.collage img {
	padding: 10px;
	max-width: 30%; /* Фотографии будут уменьшены, чтобы поместиться в контейнер */
	margin-bottom: 10px; /* Расстояние между фотографиями */
}

.redButtonADD {
	position: relative;
	width: 394px;
	height: 55px;
	margin-bottom: 30px;
	background: #a8342d;
	border-radius: 22px;
	border: 0;
	color: #ffffff;
	font-size: 18px;
	font-weight: 500;
	cursor: pointer;
	transition: background 0.3s ease-in-out;

	.arrow {
		margin-left: 5px;
		margin-top: 1px;
	}
	&:hover {
		img {
			transform: translateX(40px);
			transition: transform 0.8s ease-in-out;
		}

		&:disabled {
			background-color: #bebebe !important;
			cursor: default;
		}

		&:hover {
			background: lighten(#a8342d, 4%);
		}
		&:active {
			background: darken(#a8342d, 4%);
		}
	}
}
@keyframes button-loading {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.7;
	}
	100% {
		opacity: 1;
	}
}

form {
	label {
		font-size: px;
		margin-bottom: 10px;
		display: block;
	}

	input {
		margin-top: 15px;
		width: 100%;
		padding: 10px;
		font-size: 14px;
		margin-bottom: 16px;
		border: 1px solid #ccc;
		border-radius: 16px; /* Added border-radius */
		box-sizing: border-box;
	}
}

.RAL {
	position: relative;
	overflow: hidden;
	display: flex;
	width: 100%;
}

.RALtext {
	padding-bottom: 0;
	margin-top: 0;
}

/* Добавляем стили для выпадающего меню */

.contentBasket {
	overflow-x: hidden;
}

.favOrdEmpty {
	display: flex;
	padding: 10%;
}
.favOrdEmpty1 {
	display: flex;
	align-items: normal;
}

.zamer {
	text-wrap: wrap;
	text-transform: uppercase;
	background: linear-gradient(
		17deg,
		rgb(0, 20, 75) 0%,
		rgba(136, 56, 22, 1) 81%,
		rgba(113, 9, 9, 1) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: calc(30px + 1.8vw);
	transition: text-shadow 0.4s ease-in-out, transform 0.4s ease-in-out;
	&:hover {
		text-shadow: 0px 0px 50px rgba(113, 9, 9, 0.61);
	}
}
.phoneNumber {
	color: rgb(221, 200, 176);
	margin: 0%;
	margin-right: 20px;
	width: auto;
	font-size: calc(14px + 0.7vw);
}

.main-text {
	height: fit-content;
	text-align: center;
	color: #ffffff;
	padding-left: 20px;
	padding-right: 20px;
	text-wrap: wrap;
	font-size: calc(9px + 0.55vw);
}

.zamerWidth {
	font-size: calc(12px + 0.7vw);
	text-align: start;
	background: linear-gradient(
		17deg,
		rgb(0, 20, 75) 0%,
		rgba(136, 56, 22, 1) 81%,
		rgba(113, 9, 9, 1) 100%
	);
	color: #ffffff;
	transition: box-shadow 0.4s ease-in-out, transform 0.4s ease-in-out;
	&:hover {
		box-shadow: 0px 0px 50px rgba(113, 9, 9, 1);
	}
	p {
		padding: 20px;
	}
}

.zamerHight {
	text-align: end;
	text-transform: uppercase;
	background: linear-gradient(
		17deg,
		rgb(0, 20, 75) 0%,
		rgba(136, 56, 22, 1) 81%,
		rgba(113, 9, 9, 1) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: calc(12px + 0.7vw);
	transition: text-shadow 0.4s ease-in-out, transform 0.4s ease-in-out;
	&:hover {
		text-shadow: 0px 0px 50px rgba(113, 9, 9, 0.61);
	}
}

.textwrap {
	background: linear-gradient(
		17deg,
		rgb(0, 20, 75) 0%,
		rgba(136, 56, 22, 1) 81%,
		rgba(113, 9, 9, 1) 100%
	);
	flex-direction: column;
	font-size: calc(18px + 1vw);
	overflow: hidden;
	transition: box-shadow 0.4s ease-in-out, transform 0.4s ease-in-out;

	.nowrap {
		text-align: center;
		color: #fff;
		text-transform: uppercase;
		padding-top: 30px;
		padding-bottom: 15px;
		animation: text 10s infinite linear;
		padding-left: 100%;
		white-space: nowrap;
	}
	&:hover {
		box-shadow: 0px 0px 50px rgba(113, 9, 9, 1);
	}
}
@keyframes text {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(-160%, 0);
	}
}

.ZAMER {
	img {
		height: 50%;
		padding: 20px;
	}
}

@media (max-width: 1000px) {
	span {
		flex-direction: column;
		img {
			width: auto;
			justify-content: center;
		}
	}
}

.color-button.izum {
	background-color: #257e59;
}

.color-button.black {
	background-color: #1a1919;
}

.color-button.red {
	background-color: rgb(214, 45, 45);
}
.color-button.brown {
	background-color: rgb(122, 58, 20);
}
.color-button.cream {
	background-color: rgb(250, 195, 163);
}
.color-button.grey {
	background-color: grey;
}
.color-button.milk {
	background-color: rgb(248, 232, 222);
}
.color-button.navi {
	background-color: rgb(30, 30, 128);
}
.color-button.night {
	background-color: rgb(33, 33, 70);
}
.color-button.semigrey {
	background-color: rgb(185, 185, 185);
}

.button-drawer {
	background-color: #ffffff; // Используйте ваш цвет
	color: #3f0f0f;
	border: none;
	padding: 0.5rem;
	cursor: pointer;
	font-size: 1.2rem;
	margin: 0 0.3rem;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 1;
}

.CandeQuantity {
	border: 1px solid #ccc;
	border-radius: 16px;
}

.hamburger {
	cursor: pointer;
}

.hamburger input {
	display: none;
}

.hamburger svg {
	/* The size of the SVG defines the overall size */
	height: 3em;
	/* Define the transition for transforming the SVG */
	transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
	fill: none;
	stroke: white;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 3;
	/* Define the transition for transforming the Stroke */
	transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
		stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
	stroke-dasharray: 12 63;
}

.hamburger input:checked + svg {
	transform: rotate(-45deg);
}

.hamburger input:checked + svg .line-top-bottom {
	stroke-dasharray: 20 300;
	stroke-dashoffset: -32.42;
}
