.contain {
	display: flex;
	flex-direction: row-reverse;
}

a {
	display: flex;
	margin: 10px;
	justify-content: flex-start;
	width: 100%;
	font-size: 50%;
	white-space: nowrap;
	text-decoration: none;
	font-size: 100%;
	font-family: "Inter";
	color: #f9f9f9;
	transition: 0.3s ease-in-out;
}

.MenuDropdown {
	position: relative;
	display: flex;
	justify-content: flex-start;
	width: 100%;
	white-space: nowrap;
	text-decoration: none;
	font-size: 16px;
	font-family: "Inter";
	color: #f9f9f9;
}

.dropdown {
	margin: 10px;
	position: relative;
	display: flex;
	justify-content: flex-start;
	width: 100%;
	white-space: nowrap;
	text-decoration: none;
	font-size: 16px;
	font-family: "Inter";
	color: #f9f9f9;
}

@keyframes slideIn {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0);
	}
}

.dropdownContent {
	top: 120%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	background-color: #8a3737;
	min-width: 220px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	left: 0;
	animation: slideIn 0.3s ease-in-out;
}

.dropdowContentContent {
	top: 135%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	background-color: #8a3737;
	min-width: 220px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
}

/* Стили для пунктов меню */
.dropdowContentContent a {
	color: #f9f9f9;
	text-decoration: none;
	display: block;
}

.dropdowContentContent a:hover {
	background-color: #6b1818;
}

.dropdown:hover .dropdownContent {
	display: block;
}

/* Стили для пунктов меню */
.dropdowContent a {
	color: #f9f9f9;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdowContent a:hover {
	background-color: #6b1818;
}
